{
  "Skip to main content": "Spring til hovedindhold",
  "In Progress": "Der arbejdes",
  "Submitted": "Indsendt",
  "You haven't started or submitted an application yet": "Du er ikke startet på eller indsendt en ansøgning endnu",
  "Name": "Navn",
  "Application": "Ansøgning",
  "Registration": "Registreringsmuligheder",
  "This event is now full.": "Denne begivenhed er nu fuld.",
  "This event has now passed.": "Denne begivenhed er nu fuld.",
  "Registrations are closed.": "Tilmeldinger er lukket.",
  "Register": "Tilmelding",
  "Thank you for filling in the form.": "Tak, fordi du udfyldte formularen.",
  "You will receive an email shortly.": "Du modtager snart en e-mail",
  "Email not verified": "E-mail ikke bekræftet",
  "A verification email was sent to {{email}}.": "En bekræftelses-e-mail blev sendt til {{email}}.",
  "Please check your email.": "Tjek venligst din e-mail",
  "Email sent": "E-mail sendt",
  "Email failed!": "E-mail fejlede",
  "Resend verification email": "Gensend bekræftelses-e-mail",
  "Change to English": "Skift til dansk",
  "Locale": "Dansk",
  "Login": "Log ind",
  "Home": "Hjem",
  "My Applications": "Mine ansøgninger",
  "My Registrations": "Mine registreringer",
  "Other Content": "Andet indhold",
  "Menu": "Menu",
  "Close navigation": "Luk navigation",
  "Main Menu": "Hovedmenu",
  "Close": "Luk",
  "Applications": "Ansøgninger",
  "No applications available": "Ingen ansøgninger tilgængelige",
  "To access registrations please register or login.": "Tilmeld dig eller log ind for adgang til registrering.",
  "To access applications please register or login.": "Tilmeld dig eller log ind for adgang til ansøgninger",
  "You haven't started or submitted a registration yet": "Du er ikke startet på eller indsendt en registrering endnu",
  "Registrations": "Registreringer ",
  "No registrations available": "Ingen registreringer tilgængelige",
  "Start registration": "Start registrering",
  "Start application": "Start ansøgning",
  "Start another registration": "Start endnu en registrering",
  "Start another application": "Start endnu en ansøgning",
  "Continue registration": "Fortsæt registrering",
  "Continue application": "Fortsæt ansøgning",
  "today at {{ time }}": "I dag klokken {{time}}",
  "tomorrow at {{ time }}": "I morgen klokken {{time}}",
  "on {{ date }} at {{ time }}": "den {{date}} klokken {{time}}",
  "on {{ date }}": "den {{date}}",
  "Closes {{ dateAndTimeText }}": "Lukker {{dateAndTimeText}}",
  "Opens {{ dateAndTimeText }}": "Åbner {{dateAndTimeText}}",
  "Registrations are closed": "Lukket for registreringer",
  "Applications are closed": "Lukket for ansøgninger",
  "Loading": "Indlæser",
  "Choose a programme or subject": "Vælg et program eller fag",
  "This field is required": "Dette felt er påkrævet",
  "Choose a class or course": "Vælg et hold eller fag",
  "Choose a campus": "Vælg en campus",
  "Registration options": "Registreringsmuligheder",
  "Application options": "Ansøgningsmuligheder",
  "Cancel": "Annuller ",
  "Continue": "Fortsæt",
  "Please select an option": "Vælg venligst en mulighed",
  "You can change your selection later.": "Du har mulighed for at ændre dit valg senere.",
  "View registration": "Vis registrering",
  "View application": "Vis ansøgning",
  "Accept": "Accepter",
  "Decline": "Afvis",
  "View Submission": "Vis indsendelse",
  "Conditional offer": "Betinget tilbud",
  "Unconditional offer": "Ubetinget tilbud",
  "No events": "Ingen begivenheder",
  "Learn more": "Lær mere",
  "Upcoming Events": "Kommende begivenheder",
  "Hide map": "Skjul kort",
  "Show map": "Vis kort",
  "Please fill out the form": "Udfyld venligst formularen",
  "Data Protection Regulation": "Databeskyttelsesforordning ",
  "Public profile": "Offentlig profil",
  "Transcripts": "Udskrifter",
  "Calendar": "Kalender",
  "Settings": "Indstillinger",
  "Logout": "Log ud",
  "Someone already registered with that email address": "En person, der allerede er registreret med den e-mail-adresse",
  "Email address is invalid": "E-mail adresse er ugyldig",
  "Email already in use": "E-mail er allerede i brug",
  "Participant details": "Deltagerinformationer",
  "Please enter the details of the participant": "Indtast venligst informationer om deltageren",
  "Email address": "E-mail adresse",
  "First name": "Fornavn",
  "Last name": "Efternavn",
  "Offers": "Tilbud",
  "{{ name }} (closed)": "{{name}} (lukket)",
  "Payments": "Betalinger",
  "View your fees and make payments": "Vis dine gebyrer og foretage betalinger",
  "Paid <1></1>": "Betalt <1></1>",
  "Due in {{timeToDueDate}}": "På grund i {{timeToDueDate}}",
  "Due today": "Forfald i dag",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} for sent",
  "Due Date": "Afleveringsdato",
  "Status": "Stat",
  "Description": "Beskrivelse",
  "Amount": "Beløb",
  "Payment plan for": "Betaling plan for",
  "Alerts": "Advarsler",
  "Next payment:": "Næste betaling:",
  "See details": "Se detaljer",
  "Discount": "Rabat",
  "Total": "Total",
  "Paid": "Betalt",
  "Awaiting confirmation": "Afventer bekræftelse",
  "Due soon": "Snart forfalden",
  "Due / Overdue": "Forfalden / For sent",
  "Back to list": "Tilbage til listen",
  "Fee details": "Fee detaljer",
  "Payment Plans": "Betalingsplaner",
  "Pay now": "Betal nu",
  "Pay with Flywire": "Betal med Flywire",
  "Flywire billing details": "Flywire faktureringsoplysninger",
  "Email": "E-Mail",
  "Phone number": "Telefonnummer",
  "Address line 1": "Adresse linie 1",
  "Address line 2": "Adresse linie 2",
  "Country": "Land",
  "City": "By",
  "Zip code": "Postnummer",
  "State": "Stat",
  "Awaiting payment confirmation": "Afventer betalingsbekræftelse",
  "We are awaiting confirmation of your payment.": "Vi afventer bekræftelse af din betaling.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "Du får tilsendt en e-mail, når din betaling er modtaget. Vælg en anden metode, hvis du havde problemer med at foretage din betaling.",
  "Payment": "Betaling",
  "Missing {{amount}}": "Manglende {{amount}}",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Der opstod en fejl i din indsendelse, prøv igen om et par sekunder. Hvis problemet fortsætter, bedes du kontakte os.",
  "{{ name }} (not currently available)": "{{ name }} (ikke tilgængeligt for øjeblikket)",
  "{{ name }} (not available yet)": "{{ name }} (ikke tilgængelig endnu)",
  "Registrations are currently closed.": "Tilmeldingerne er i øjeblikket lukket.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Tilmeldingerne åbner den {{registrationsOpenDate}}.",
  "Select an option": "Vælg en mulighed",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} den {{dateTime}}",
  "Product catalog": "Produktkatalog",
  "Search for a programme or course": "Søg efter et program eller kursus",
  "Start date": "Startdato",
  "End date": "Slutdato",
  "Duration": "Varighed",
  "Location": "Sted",
  "Price": "Pris",
  "Application deadline": "Deadline for ansøgning",
  "Registration deadline": "Deadline for registrering",
  "Apply": "Ansøg",
  "Details": "Detaljer",
  "All": "Alle",
  "Now until the end of next month": "I dag frem til udgangen af næste måned",
  "In 1-6 months": "Om 1 - 6 måneder",
  "In 6-12 months": "Om 6 - 12 måneder",
  "In 1 year": "Om 1 år",
  "Theme": "Tema",
  "Pay with Stripe": "Betal med Stripe",
  "Stripe payment details": "Stripe betalingsoplysninger",
  "Your payment was processed": "Din betaling blev behandlet",
  "Edit application": "Rediger ansøgning",
  "Edit registration": "Rediger registrering",
  "Have a discount code?": "Har du en rabatkode?",
  "In order to submit you are required to pay a fee.": "For at indsende skal du betale en gebyr.",
  "All fees paid": "Alle gebyrer betalt",
  "Next step:": "Næste trin:",
  "Sign & Submit": "Underskriv og indsend",
  "Submit": "Indsend",
  "I accept the privacy policy": "Jeg godkender privatlivspolitikken",
  "Privacy Policy": "Privatlivspolitik",
  "All fees handled.": "Alle gebyrer håndteret",
  "Next step.": "Næste skridt.",
  "Finish your application by clicking the button below.": "Afslut din ansøgning ved at klikke på knappen nedenfor.",
  "Finish your registration by clicking the button below.": "Afslut din registrering ved at klikke på knappen nedenfor.",
  "Check your inbox": "Tjek din indbakke",
  "A new link has been sent.": "Et nyt link er blevet sendt.",
  "Access expired": "Adgang udløbet",
  "Click the button below to receive a new link in your inbox.": "Klik på knappen nedenfor for at modtage et nyt link i din indbakke.",
  "Send new link": "Send nyt link",
  "Payment link is invalid": "Betalingslinket er ugyldigt",
  "This payment has been canceled or does not exist.": "Denne betaling er blevet annulleret eller findes ikke.",
  "Fee has been paid": "Gebyret er betalt",
  "The requester has been notified.": "Anmoderen er blevet underrettet.",
  "On {{date}}, this fee was sent to an external payer.": "Den {{date}} blev denne afgift sendt til en ekstern betaler.",
  "Name is invalid": "Navn er ugyldigt",
  "Manage payer": "Administrer betaler",
  "Define payer": "Definer betaler",
  "Cancel external payer": "Annuller ekstern betaler",
  "Resend fee details": "RSend afgiftsoplysninger igen",
  "Send fee": "Send afgift",
  "An email containing all the payment details will be sent to the external payer.": "En e-mail, der indeholder betalingsoplysningerne bliver sendt til den eksterne betaler.",
  "Payer's email address": "Betalers e-mailadresse",
  "Payer's name": "Betalers navn",
  "Actions": "Handlinger",
  "External payer access was revoked": "Ekstern betaleradgang blev ophævet",
  "External payer details saved successfully": "Oplysninger om ekstern betalende gemt med succes",
  "Fee paid": "Afgift betalt"
}
