{
  "Skip to main content": "Ga naar de hoofdinhoud",
  "In Progress": "In uitvoering",
  "Submitted": "Ingediend",
  "You haven't started or submitted an application yet": "U bent nog niet begonnen of heeft nog geen aanvraag ingediend.",
  "Name": "Naam",
  "Application": "Inschrijving",
  "Registration": "Registratiemogelijkheden",
  "This event is now full.": "Dit evenement is nu vol.",
  "This event has now passed.": "Dit evenement is nu voorbij.",
  "Registrations are closed.": "De inschrijvingen zijn gesloten.",
  "Register": "Registreer",
  "Thank you for filling in the form.": "Bedankt voor het invullen van het formulier.",
  "You will receive an email shortly.": "U ontvangt binnenkort een e-mail.",
  "Email not verified": "E-mailadres niet geverifieerd",
  "A verification email was sent to {{email}}.": "Er is een verificatiemail verzonden naar {{email}}.",
  "Please check your email.": "Controleer uw e-mail.",
  "Email sent": "Email verzonden",
  "Email failed!": "E-mail is mislukt!",
  "Resend verification email": "Verzend verificatie-e-mail opnieuw",
  "Change to English": "Wijzig naar Nederlands",
  "Locale": "Nederlands",
  "Login": "Inloggen",
  "Home": "Home",
  "My Applications": "Mijn Aanvragen",
  "My Registrations": "Mijn Registraties",
  "Other Content": "Andere inhoud",
  "Menu": "Menu",
  "Close navigation": "Navigatie sluiten",
  "Main Menu": "Hoofdmenu",
  "Close": "Sluit",
  "Applications": "Aanvragen",
  "No applications available": "Geen aanvragen beschikbaar",
  "To access registrations please register or login.": "Registreer of log in om aanvragen te bekijken",
  "To access applications please register or login.": "Registreer of log in om aanvragen te bekijken",
  "You haven't started or submitted a registration yet": "Je bent nog niet begonnen of hebt nog geen registratie ingediend",
  "Registrations": "Registraties",
  "No registrations available": "Geen registraties beschikbaar",
  "Start registration": "Begin registratie",
  "Start application": "Begin aanmelding",
  "Start another registration": "Begin een andere registratie",
  "Start another application": "Begin een andere aanmelding",
  "Continue registration": "Doorgaan met de registratie",
  "Continue application": "Hervat aanmelding",
  "Registrations are closed": "Registraties zijn gesloten",
  "Applications are closed": "Aanvragen zijn gesloten",
  "Loading": "Laden",
  "Choose a programme or subject": "Kies een programma of onderwerp",
  "This field is required": "Dit veld is vereist",
  "Choose a class or course": "Kies een les of cursus",
  "Choose a campus": "Kies een campus",
  "Registration options": "Registratiemogelijkheden",
  "Application options": "Toepassingsopties",
  "Cancel": "Annuleer",
  "Continue": "Doorgaan",
  "Please select an option": "Selecteer een optie",
  "You can change your selection later.": "U kunt uw selectie later wijzigen.",
  "View registration": "Bekijk de registratie",
  "View application": "Bekijk de Aanvragen",
  "Accept": "Accepteer",
  "Decline": "Weiger",
  "View Submission": "Bekijk inzending",
  "Conditional offer": "Voorwaardelijk aanbod",
  "Unconditional offer": "Onvoorwaardelijk aanbod",
  "No events": "Geen evenementen",
  "Learn more": "Kom meer te weten",
  "Upcoming Events": "Aankomende evenementen",
  "Hide map": "Verberg kaart",
  "Show map": "Kaart weergeven",
  "Please fill out the form": "Gelieve het formulier in te vullen",
  "Data Protection Regulation": "Verordening inzake gegevensbescherming",
  "Public profile": "Publiek profiel",
  "Transcripts": "Transcripties",
  "Calendar": "Kalender",
  "Settings": "Instellingen",
  "Logout": "Uitloggen",
  "Someone already registered with that email address": "Iemand heeft zich al geregistreerd met dat e-mailadres",
  "Email address is invalid": "E-mailadres is ongeldig",
  "Email already in use": "Iemand heeft dit e-mailadres al in gebruik genomen",
  "Participant details": "Details deelnemer",
  "Please enter the details of the participant": "Gelieve de details van de deelnemer in te voeren",
  "Email address": "E-mailadres",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Offers": "Aanbiedingen",
  "{{ name }} (closed)": "{{name}} (Gesloten)",
  "Closes {{ dateAndTimeText }}": "Sluit {{ dateAndTimeText }}",
  "Opens {{ dateAndTimeText }}": "Opent {{ dateAndTimeText }}",
  "on {{ date }}": "op {{ date }}",
  "on {{ date }} at {{ time }}": "op {{ date }} op {{ time }}",
  "today at {{ time }}": "vandaag om {{ time }}",
  "tomorrow at {{ time }}": "morgen om {{ time }}",
  "Payments": "Betalingen",
  "View your fees and make payments": "Bekijk uw kosten en doe betalingen",
  "Paid <1></1>": "Betaald <1></1>",
  "Due in {{timeToDueDate}}": "Verschuldigd in {{timeToDueDate}}",
  "Due today": "Vandaag vervallen",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} achterstallig",
  "Due Date": "Opleveringsdatum",
  "Status": "Status",
  "Description": "Omschrijving",
  "Amount": "Bedrag",
  "Payment plan for": "Betalingsplan voor",
  "Alerts": "Waarschuwingen",
  "Next payment:": "Volgende betaling:",
  "See details": "Zie de details",
  "Discount": "Korting",
  "Total": "Totaal",
  "Paid": "Betaald",
  "Awaiting confirmation": "In afwachting van bevestiging",
  "Due soon": "Binnenkort verschuldigd",
  "Due / Overdue": "Verschuldigd / Te laat",
  "Back to list": "Back to list",
  "Fee details": "Kostengegevens",
  "Payment Plans": "Betalingsplannen",
  "Pay now": "Betaal nu",
  "Pay with Flywire": "Betaal met Flywire",
  "Flywire billing details": "Flywire factureringsgegevens",
  "Email": "E-mail",
  "Phone number": "Telefoonnummer",
  "Address line 1": "Adresregel 1",
  "Address line 2": "Adresregel 2",
  "Country": "Land",
  "City": "Stad",
  "Zip code": "Postcode",
  "State": "Geef aan",
  "Awaiting payment confirmation": "In afwachting van betalingsbevestiging",
  "We are awaiting confirmation of your payment.": "We wachten op bevestiging van uw betaling.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "U ontvangt een e-mail wanneer uw betaling succesvol is ontvangen. Selecteer een andere methode als u problemen heeft gehad met uw betaling.",
  "Missing {{amount}}": "Ontbreekt {{amount}}",
  "Payment": "Betaling",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Er is een fout opgetreden bij uw inzending, probeer het over een paar seconden opnieuw. Als het probleem zich blijft voordoen, neem dan contact met ons op.",
  "{{ name }} (not currently available)": "{{ name }} (momenteel niet beschikbaar)",
  "{{ name }} (not available yet)": "{{ name }} (nog niet beschikbaar)",
  "Registrations are currently closed.": "De inschrijvingen zijn momenteel gesloten.",
  "Registrations will open on {{ registrationsOpenDate }}.": "De inschrijvingen openen op {{registrationsOpenDate}}.",
  "Select an option": "Selecteer een optie",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} bij {{dateTime}}",
  "Product catalog": "Productcatalogus",
  "Search for a programme or course": "Zoek een programma of cursus",
  "Start date": "Begindatum",
  "End date": "Einddatum",
  "Duration": "Looptijd",
  "Location": "Plaats",
  "Price": "Prijs",
  "Application deadline": "Uiterste datum aanvraag",
  "Registration deadline": "Uiterste datum inschrijving",
  "Apply": "Aanmelden",
  "Details": "Details",
  "All": "Alle",
  "Now until the end of next month": "Vandaag tot het einde van volgende maand",
  "In 1-6 months": "Over 1 - 6 maanden",
  "In 6-12 months": "Over 6 - 12 maanden",
  "In 1 year": "Over 1 jaar",
  "Theme": "Thema",
  "Pay with Stripe": "Betaal met Stripe",
  "Stripe payment details": "Stripe betalingsgegevens",
  "Your payment was processed": "Uw betaling is verwerkt",
  "Edit application": "Bewerk aanvraag",
  "Edit registration": "Bewerk registratie",
  "Have a discount code?": "Heb je een kortingscode?",
  "In order to submit you are required to pay a fee.": "Om in te dienen moet je een vergoeding betalen.",
  "All fees paid": "Alle kosten betaald",
  "Next step:": "Volgende stap:",
  "Sign & Submit": "Ondertekenen en indienen",
  "Submit": "Indienen",
  "I accept the privacy policy": "Ik accepteer het privacybeleid",
  "Privacy Policy": "Privacybeleid",
  "All fees handled.": "Alle kosten afgehandeld",
  "Next step.": "Volgende stap.",
  "Finish your application by clicking the button below.": "Voltooi uw aanvraag door op de onderstaande knop te klikken.",
  "Finish your registration by clicking the button below.": "Voltooi uw registratie door op de onderstaande knop te klikken.",
  "Check your inbox": "Controleer uw inbox",
  "A new link has been sent.": "Een nieuwe link is verzonden.",
  "Access expired": "Toegang verlopen",
  "Click the button below to receive a new link in your inbox.": "Klik op de knop hieronder om een nieuwe link in uw inbox te ontvangen.",
  "Send new link": "Nieuwe link verzenden",
  "Payment link is invalid": "Betaallink is ongeldig",
  "This payment has been canceled or does not exist.": "Deze betaling is geannuleerd of bestaat niet.",
  "Fee has been paid": "Vergoeding is betaald",
  "The requester has been notified.": "De aanvrager is op de hoogte gebracht.",
  "On {{date}}, this fee was sent to an external payer.": "Deze vergoeding is op {{date}} naar een externe betaler verzonden.",
  "Name is invalid": "Naam is ongeldig",
  "Manage payer": "Betaler beheren",
  "Define payer": "Definieer betaler",
  "Cancel external payer": "Externe betaler opzeggen",
  "Resend fee details": "Gegevens vergoeding opnieuw verzenden",
  "Send fee": "Vergoeding verzenden",
  "An email containing all the payment details will be sent to the external payer.": "Er wordt een e-mail met alle betalingsgegevens naar de externe betaler verzonden.",
  "Payer's email address": "E-mailadres betaler",
  "Payer's name": "Naam betaler",
  "Actions": "Acties",
  "External payer access was revoked": "Toegang externe betalers is ingetrokken",
  "External payer details saved successfully": "Externe betalergegevens succesvol opgeslagen",
  "Fee paid": "Vergoeding betaald"
}
