import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import { EditorState } from 'prosemirror-state'

import { EditorContent } from '@tiptap/react'

import { AppDataProvider } from 'shared/contexts/AppData'
import ReactQueryClientProvider from 'shared/utils/react-query-client-provider'

import Toolbar from 'apps/EmailTemplates/components/PlainTextEditor/Toolbar'

// Plugin to use the tip tap editor in the grapejs editor
export const textEditorPlugin = (editor, options) => {
  const tipTapEditor = options.editor

  // Replace the original toolbar by our custom one
  editor.onReady(() => {
    render(
      <ReactQueryClientProvider>
        <AppDataProvider value={window.SERVER_DATA}>
          <Toolbar
            withoutFontFormat
            editor={options.editor}
            modalTargetContainer={editor.RichTextEditor.getToolbarEl()}
          />
        </AppDataProvider>
      </ReactQueryClientProvider>,
      editor.RichTextEditor.getToolbarEl()
    )
  })

  editor.setCustomRte({
    originalElement: null,
    parseContent: false,
    disable: function () {
      this.originalElement.style.display = 'block'
      this.updateElement()
      unmountComponentAtNode(this.editorDivElement)

      this.editorDivElement.remove()

      tipTapEditor.off('transaction', this.updateElement)
    },
    updateElement: function () {
      this.originalElement.innerHTML = tipTapEditor.getHTML()
    },
    enable: function (el) {
      this.originalElement = el
      this.mountTipTap(el)
      this.clearHistoryAndSetContent(el.innerHTML)
    },
    clearHistoryAndSetContent: function (html) {
      tipTapEditor.commands.setContent(html)

      const newEditorState = EditorState.create({
        doc: tipTapEditor.state.doc,
        plugins: tipTapEditor.state.plugins,
        schema: tipTapEditor.state.schema
      })
      tipTapEditor.view.updateState(newEditorState)
    },
    mountTipTap: function (targetTextToEdit) {
      // Create a new div to render the editor
      this.editorDivElement = document.createElement('div')
      this.editorDivElement.id = 'editor-tiptap'

      // Copy the style from the original element to the new div
      this.editorDivElement.setAttribute(
        'style',
        targetTextToEdit.getAttribute('style')
      )
      // Insert the new div before the original element
      targetTextToEdit.parentNode.insertBefore(
        this.editorDivElement,
        targetTextToEdit
      )

      setTimeout(() => {
        // Hide the original element
        targetTextToEdit.style.display = 'none'

        // By the end we render the editor
        render(<EditorContent editor={tipTapEditor} />, this.editorDivElement)
      }, 200)
    }
  })
}
